import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"
import { graphql } from "gatsby"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import HeroDesignSection from "../components/static-sections/HeroDesignSection"

/** dynamic sections */
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import NorhartBlackCircleIcon from "../../assets/norhart-black-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.black

/** props */
interface Props {
  data?: any
  didSubmit?: boolean
}

/** const */
export const DesignPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/design/",
          name: "Design| Norhart",
          image: `${config.siteMetadata.siteUrl}/design/design-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Apartment Design | Norhart"
      description="Norhart Apartment Design"
      keywords="norhart, apartments, design"
      imageTwitter={`${config.siteMetadata.siteUrl}/design/norhart-design-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/design/norhart-design-platform-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroDesignSection />

      <RibbonSection title="Norhart Design" tagLine="Design Driven Apartments" colorPalette={colorPalette} />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartBlackCircleIcon}
        iconTitle="Norhart Apartments"
        title="A Design Driven Living Enviroment"
        subTitle={`"Insert Text"`}
        image=""
        imageAlt=""
        textColor={colorPalette.textColor}
        asoEffect="fade-in"
      />
    </Layout>
  )
}

/** export */
/** export */
export default DesignPage
